<script setup>
import fetchIntercept from 'fetch-intercept';
import { onMounted, ref } from 'vue';
import { router, useForm, usePage, Link } from '@inertiajs/vue3';
import { EnvelopeIcon, PhoneIcon } from '@heroicons/vue/24/solid';
import { BackdropModal } from '@goodvibes/kudoslink';
import { Card, Spinner, TertiaryButton, TextInput } from '@/Components';
import { useModal } from '@/composables';
import { Pattern } from '../../images/backgrounds';

const page = usePage();
const modal = useModal(false);

// Visitor's country ISO code
const country = ref(null);

const couponForm = useForm({
    code: '',
});

let intendedUrl = null;

onMounted(() => {
    const unregister = fetchIntercept.register({
        response: async function (originalResponse) {
            const response = originalResponse.clone();

            if (response.status === 403 && response.headers.get('Content-Type')?.includes('application/json')) {
                const data = await response.json();

                if (data.reason === 'paywall') {
                    const { message, location, userType } = data;
                    handlePaywallResponse(data);
                }
            }

            return originalResponse;
        },
    });

    return () => {
        unregister();
    };
});

router.on('invalid', e => {
    const { response } = e.detail;
    if (response.status === 403 && response.data.reason === 'paywall') {
        e.preventDefault();
        const { message, location, userType } = response.data;
        intendedUrl = response.request?.responseURL;
        handlePaywallResponse(response.data);
    }
});

router.on('navigate', e => {
    modal.close();
    intendedUrl = null;
    couponForm.code = '';
    country.value = null;
});

function handlePaywallResponse(data) {
    country.value = data.location.iso_code;
    modal.open();
}

function redeemCoupon() {
    couponForm.get(route('coupons.redeem', { code: couponForm.code }), {
        preserveState: true,
        onSuccess: () => {
            if (intendedUrl) {
                router.visit(intendedUrl);
            }
            modal.close();
        },
    });
}
</script>

<template>
  <BackdropModal class="!z-[10001] overflow-scroll" :show="modal.isOpen" @close="modal.close()">
        <div class="flex justify-center items-center h-full gap-y-4">
            <!-- banner for unregistered users -->
            <Card 
                v-if="page.props.auth.user?.isDemoUser"
                class="flex flex-col justify-between gap-y-8 w-full max-w-3xl mx-auto"
                :bgImage="Pattern"
            >
                <div class="flex flex-col">
                    <h1 class="text-2xl md:text-4xl lg:text-5xl font-bold max-w-xl">Want to get 5 free roleplays each month?</h1>
                </div>
                
                <div class="flex max-w-3xl mx-auto items-center gap-x-4">
                    <TertiaryButton :as="Link" :href="route('register')">Signup</TertiaryButton>
                </div>
            </Card>

            <!-- banner for Slovak users -->
            <Card
                v-else-if="country?.toLowerCase() === 'sk'"
                class="flex flex-col justify-between gap-y-8 w-full max-w-3xl mx-auto"
                :bgImage="Pattern"
            >
                <div class="flex flex-col">
                    <h1 class="text-2xl md:text-4xl lg:text-5xl font-bold max-w-xl">Want to unlock the full potential?</h1>
                    <p class="text-md md:text-xl mt-4 md:mt-6">For advanced features and full access without limitations, connect with our partner for Slovakia, Maxman Consultants</p>
                </div>
                <div class="flex flex-wrap md:flex-nowrap gap-4 md:justify-around">
                    <div class="flex flex-col gap-y-2">
                        <a href="mailto:kuklis@maxman-consultants.com" class="flex gap-x-3 items-center mx-auto">
                            <div class="p-2 aspect-square rounded-full bg-gradient-to-br from-[#6c14e1] to-[#9649dd] flex items-center justify-center">
                                <EnvelopeIcon class="fill-white w-5"/>
                            </div>
                            <div class="text-lg font-bold">balis@maxman-consultants.com</div>
                        </a>
                    </div>
                    <div class="flex flex-col gap-y-2">
                        <a href="tel:+421915718688" class="flex gap-x-3 items-center mx-auto">
                            <div class="p-2 aspect-square rounded-full bg-gradient-to-br from-[#6c14e1] to-[#9649dd] flex items-center justify-center">
                                <PhoneIcon class="fill-white w-5"/>
                            </div>
                            <div class="text-lg font-bold">+421 915 718 688</div>
                        </a>
                    </div>
                </div>
                <form class="flex flex-wrap max-w-3xl mx-auto items-start gap-4" @submit.prevent="redeemCoupon">
                    <TextInput v-model="couponForm.code" :errors="couponForm.errors?.code ? [couponForm.errors.code] : undefined" placeholder="Your coupon code" class="grow" />
                    <TertiaryButton type="submit" :disabled="couponForm.processing">
                        <template v-if="couponForm.processing">
                            <Spinner class="text-current w-5 inline-block animate-spin" />
                        </template>
                        {{ couponForm.processing ? 'Activating...' : 'Activate' }}
                    </TertiaryButton>
                </form>
            </Card>

            <!-- Global paywall banner -->
            <Card
                v-else
                class="flex flex-col justify-between gap-y-8 w-full max-w-3xl mx-auto"
                :bgImage="Pattern"
            >
                <div class="flex flex-col">
                    <h1 class="text-2xl md:text-4xl lg:text-5xl font-bold max-w-xl">Want to unlock the full potential?</h1>
                    <p class="text-md md:text-xl mt-4 md:mt-6">For advanced features and full access without limitations, contact us.</p>
                </div>
                <div class="flex flex-wrap md:flex-nowrap gap-4 md:justify-around">
                    <div class="flex flex-col gap-y-2">
                        <a href="mailto:hello@personeo.ai" class="flex gap-x-3 items-center mx-auto">
                            <div class="p-2 aspect-square rounded-full bg-gradient-to-br from-[#6c14e1] to-[#9649dd] flex items-center justify-center">
                                <EnvelopeIcon class="fill-white w-5"/>
                            </div>
                            <div class="text-lg font-bold">hello@personeo.ai</div>
                        </a>
                    </div>
                    <div class="flex flex-col gap-y-2">
                        <a href="tel:+421910547257" class="flex gap-x-3 items-center mx-auto">
                            <div class="p-2 aspect-square rounded-full bg-gradient-to-br from-[#6c14e1] to-[#9649dd] flex items-center justify-center">
                                <PhoneIcon class="fill-white w-5"/>
                            </div>
                            <div class="text-lg font-bold">+421 910 547 257</div>
                        </a>
                    </div>
                </div>
                <form class="flex flex-wrap max-w-3xl mx-auto items-start gap-4" @submit.prevent="redeemCoupon">
                    <TextInput v-model="couponForm.code" :errors="couponForm.errors?.code ? [couponForm.errors.code] : undefined" placeholder="Your coupon code" class="grow" />
                    <TertiaryButton type="submit" :disabled="couponForm.processing">
                        <template v-if="couponForm.processing">
                            <Spinner class="text-current w-5 inline-block animate-spin" />
                        </template>
                        {{ couponForm.processing ? 'Activating...' : 'Activate' }}
                    </TertiaryButton>
                </form>
            </Card>
        </div>
    </BackdropModal>
</template>
